import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import things_logo from '../../assets/images/projects/service/tech/things-logo.jpg'
import bsmart_logo from '../../assets/images/projects/service/bsmart/bsmart-logo.jpg'
import pic02 from '../../assets/images/projects/14.png'
import { useRef } from 'react'


const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const ServiceDesignTecne = props => {

  const outcomeDiv = useRef(null);

  const scrolLWithUseRef = () => outcomeDiv.current?.scrollIntoView({ block: "start", behavior: "smooth" });

  return (
    <Layout>
    <Helmet>
      <title>Service Design - bsmart</title>
      <meta name="Service Design - bsmart" content="Service Design" />
    </Helmet>

    <div id="main" className="alt">
      <section id="work-banner">
        <div>
          <span className="image main">
            {/* <Img fluid={props.data.bsmartcover.childImageSharp.fluid} alt="" /> */}
            <Img fluid={props.data.tecnecover.childImageSharp.fluid} alt="" />
          </span>
        </div>
      </section>

      <div className="project-wrapper">
        <div className="project-content">
          <header>
            <p className="maisontags">Workshop, Research, Digital UX/UI, Interaction design, User experience, Business design, Product design</p>
            {/* <span className="tag">WORKSHOP</span>
            <span className="tag">RESEARCH</span>
            <span className="tag">DIGITAL UX</span>
            <span className="tag">DIGITAL UI</span>
            <span className="tag">INTERACTION DESIGN</span>
            <span className="tag">USER EXPERIENCE</span>
            <span className="tag">BUSINESS DESIGN</span>
            <span className="tag">PRODUCT DESIGN</span> */}
            <h1>b-smart.</h1>
            <h3>
              b-smart is an ecosystem of physical & digital products that empower 
              users to manage their personal data and privacy in a human-centric way; 
              by making their choices about data explicit, deliberate, transparent & reversible.
            </h3>
            <br />
            <h3>
              Augmented Reality (AR) feature of the mobile app provides a 
              transparent & engaging way to track data habits and grasp 
              the technicalities regarding this complex topic.
            </h3>
            <br />
            <h3>
              Furthermore, physical products give users a simple way to control
              how and when their data is collected by their IoT devices at home.
            </h3>
          </header>
          <br />
          <section className="header-post">
            <div className="headerpost">
              <div className="headerpost-left">
                <p>
                  *This project was carried out while working at the design
                  agency, THINGS, in Milan.
                </p>
                <p>
                  <a href="/say-hello"><u>Get in touch</u></a> with me for more 
                  details related to this project; such as my
                  role, the problems i faced, the processes i 
                  used and how i addressed the challenges.
                </p>
              </div>
              <div className="headerpost-right">
                <div className="client-image">
                  {/* <Img fluid={props.data.things_logo.childImageSharp.fluid} /> */}
                  <img className="span-4" src={things_logo} alt="things logo" />
                </div>
                <br />
                <p>
                  Things.is a design and innovation agency for the Internet of
                  Things. We create products and services with their own
                  experiential ecosystems outlined by multi-channel touch
                  points. We envision products and services in the IoT era
                  through a human-centred approach. www.things.is
                </p>
              </div>            

            </div>
          </section>


          <div className="process">           
          <div className="process-left">
                <h4>
                Context of the project
                </h4>
                <p>
                This project has 2 phases. Phase 1 involved an exploratory 
                research and conceptualisation of IoT products. In phase 2, 
                the concepts defined in phase 1 will be developed into working 
                prototypes for pilot testing.
                </p>
                <p>
                Below, only phase 1 of the project is described. 
                </p>
          </div>
          <div className="process-right">
                <h4>
                Project overview
                </h4>
                <span className="credits-text">
                  <strong>PROJECT TYPE:</strong> Designing a human-centric approach towards
                   privacy & data transparency while using IoT devices at home.
                </span>
                <br />
                <span className="credits-text">
                  <strong>TEAM MEMBERS:</strong> Design team of
                  THINGS, Milan
                </span>
                <br />
                <span className="credits-text">
                  <strong>MY ROLE:</strong> Desk research, preparing & conducting user 
                  interviews and surveys, preparing & facilitating workshops, ideation 
                  & finalising of concept, mapping customer journey & user flows, 
                  wireframing, visual design and business design.
                </span>
                <div className="challenge-client">
                <br />
                <h5>CLIENT</h5>
                  <div className="client-image">
                    <img className="span-5" src={bsmart_logo} alt="bsmart logo" />
                  </div>
                </div>
            </div>
            </div>


          <section className="challenge-section">
            <span className="project-step-number">01</span>
            <h2>the challenge</h2>
            <div className="challenge">
              <div className="challenge-desc">
                <p>
                In a tech-driven world, the economy runs on our personal data. 
                No one likes to feel as if they’re being watched, yet with 
                technology continuing to mature, the power of digital is 
                so great that brands have started to abuse it.
                </p>
                <p>
                Consumers’ personal data is being used and monetized without 
                their knowledge or consent. And with this abuse of power has 
                come the erosion of the trust that once existed between 
                businesses and consumers. 
                </p>
              </div>           
            </div>

            <div className="image">
              <Img fluid={props.data.tecnechallenge.childImageSharp.fluid} />
            </div>

            <div className="challenge">
            <div className="challenge-desc">

            <p className="half-textlarge">
            Privacy and data protection can no longer be an afterthought, 
            but it should be a core component of the user’s experience. 
            </p>

            
            <p>
            With the advent of a large number of connected objects, 
            able to sense our environment, it is of fundamental importance 
            to give to all users means to control the privacy settings.  
            </p>
            <p>
            On top of it, several connected objects may not even have 
            displays (or just very basic ones), making this task even 
            more difficult. 
            </p>
            </div>
            </div>

            <div className="project-outcome">
                <span className="project-outcome-link" onClick={scrolLWithUseRef}>
              see project outcomes >>
                </span>
              </div>

          </section>

          <section className="process-section">
            <span className="project-step-number">02</span>
            <h2>the approach</h2>

            <div className="process">         
              <div className="process-right">
              <p>The b-smart project started with an exploration around three main questions:</p>
                <ol>
                  <li>
                    Is there a possibility to develop a user-centric 
                    interface for setting up privacy controls & 
                    tracking data collected by connected devices?
                  </li>
                  <br />
                  <li>
                    Could we create a clear user experience that 
                    propagates the values of transparency of personal 
                    data collected by IoT devices & brands?
                  </li>
                  <br />
                  <li>
                    Does this make any sense, business wise?
                  </li>
                </ol>
              </div>
            </div>

            <div className="process-image">
              <Img fluid={props.data.tecne_process.childImageSharp.fluid} />
            </div>
           

            <div className="changebg">
            <div className="process">           
            <div className="process-left">
                  <h4>
                  Desk research on IoT devices
                  </h4>
              </div>
              <div className="process-right">
              <p>
                In order to better understand the problem we uncovered 
                areas for research by exploring the different forces 
                that might influence the problem. This involved deep 
                diving on the context where IoT devices are present.
              </p> 
              <p className="half-textlarge">
              The smart home context was found to be a place 
              where users have a close and private relationship 
              with IoT devices. 
              </p>               
              </div>
            </div>

            <div className="image">
              <Img fluid={props.data.tecneprocess3.childImageSharp.fluid} />
            </div>

            <div className="process">           
            <div className="process-right">
              <p>
                We examined various things in the home context such as:
              </p>
                <ol>
                  <li>
                  Types of Iot devices used and their data collection behaviours.
                  </li>
                  <li>
                  How users are managing these devices and how much control they have over it.
                  </li>
                  <li>
                  Scenarios where personal data is used and its effect on privacy and transparency.
                  </li>
                  <li>
                  Reasons for data collection & type of data collected by devices & brands.
                  </li>
                  <li>
                  Main concerns of users, brands & other stakeholders in this context.
                  </li>
                </ol>
              </div>
            </div>

            <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.tecneprocess4.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess5.childImageSharp.fluid} />
            </Slider>
            
            
            <p className="full-textlarge">
              We dug deeper by analysing the smart home ecosystem so as 
              to get a bigger picture of the relationship IoT device(s) 
              have with users, other objects and its surroundings. And 
              the types of interactions, data, information and material 
              flows that occur between them.
            </p>
            

            <div className="image">
              <Img fluid={props.data.tecneprocess6.childImageSharp.fluid} />
            </div>
            </div>


            <div className="process">           
            <div className="process-left">
                  <h4>
                  Contextual interviews
                  </h4>
              </div>
              <div className="process-right">
              <p>
              The desk research gave us the foundational 
              understanding, which was used to explore 
              quantitively about this topic.
              </p> 
              <p>
              We conducted contextual interviews 
              in the homes of smart device owners. 
              </p>               
              </div>
            </div>

            <p className="full-textlarge">
            Our goal was to understand deeply the needs, 
            pain points and the different causes & 
            relationships that influence users' 
            approach, mindset and behaviour 
            towards IoT devices at home. 
            </p>
            
            <div className="image">
              <Img fluid={props.data.tecneprocess7.childImageSharp.fluid} />
            </div>

            <div className="process">           
            <div className="process-right">
              <p>
              Some of the assumptions we validated were:
              </p>
                <ol>
                  <li>
                  People want to understand the complex world/mechanisms of personal data and privacy.
                  </li>
                  <br />
                  <li>
                  People want to take that extra steps/effort to make choices to control what 
                  data they share and when they share it, with whom they share it with. 
                  More choices can mean more confusion.
                  </li>
                  <br />
                  <li>
                  People want to monetise their data.
                  </li>
                </ol>
              </div>
            </div>

            <p className="full-textlarge">
            "IoT products are useful, but they are like black-boxes to me and thus I
            have concerns about security and privacy... also I don't 
            know how to adapt and adjust device settings 
            in a way that might reduce these fears" 
            </p>

            <p className="full-textlarge">
            "This topic is very important to me, but it is 
            too complex for me to understand, which makes me 
            not interested to know more... maybe there is a 
            simple, non geeky way to know what's happening" 
            </p>



            <div className="changebg">

            <div className="process">           
            <div className="process-left">
              <h4>
              Quantitative data
              </h4>
              </div>
              <div className="process-right">
              <p className="half-textlarge">
              Quantitative data to support our 
              findings from desk research and interviews.
              </p> 
              <p>
              We decided to conduct a survey to 
              further help us re-confirm our 
              assumptions over a large number of users
              </p>               
              </div>
            </div>

            <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.tecneprocess8.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess9.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess10.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess11.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess12.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess13.childImageSharp.fluid} />
            </Slider>

            <div className="process">           
            <div className="process-left">
              <p className="half-textlarge">
              71% of people are unclear about which 
              third parties their data is being given or sold to.
              </p>
              </div>
              <div className="process-right">
              <p className="half-textlarge">
              76% find it difficult to understand, find, 
              manage and control preferences related to 
              their personal data.
              </p>              
              </div>
            </div>
            </div>

            <div className="process">           
            <div className="process-left">
              <h4>
              Analysing research to formulate insights
              </h4>
              </div>
              <div className="process-right">
              <p>
              Following all the research, we came 
              back to our lab and mapped out our findings. 
              </p>               
              </div>
            </div>
            

            <div className="image">
              <Img fluid={props.data.tecneprocess14.childImageSharp.fluid} />
            </div>

            <div className="process">           
              <div className="process-right">
              <p>
              The problem lied in what’s happening with 
              users’ data; taken together over time, 
              intimate digital patterns emerge. 
              </p>
              <p>
              People's habits, movements, relationships, preferences, 
              beliefs, and secrets are revealed to those who analyze 
              and profit from them, like businesses and data brokers. 
              </p>                
              </div>
            </div>

            <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.tecneprocess15.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess16.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess17.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess18.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess19.childImageSharp.fluid} />
            </Slider>

            <div className="process">           
              <div className="process-right">
              <p>
              So, what’s the right way to navigate these complexities? 
              </p>
              <p>
              We mapped out a high level end-to-end journey highlighting 
              the key phases of the user’s experience. This allowed us 
              to identify instances where the user gives their personal 
              data in exchange for value they receive from the device/service.  
              </p>                
              </div>
            </div>

            <p className="full-textlarge">
            We singled out points when users data is collected when 
            they are aware of compared to when its taken in the 
            background without their knowledge. Furthermore we 
            defined how this data transfer happens; physical, 
            digital or spatial touchpoints and the context of 
            the user at the point of the data exchange. 
            </p>

            <div className="image">
              <Img fluid={props.data.tecneprocess20.childImageSharp.fluid} />
            </div>

            <div className="bluebg">
            <p className="full-textlargebluebg">
            It’s a tricky situation: Consumers want personalized services 
            that are relevant to their past behavior and future needs. 
            In order to execute on this level of personalization, 
            companies must collect large amounts of data. However, 
            consumers only want some of their data used and only 
            in a way that they are comfortable with. 
            </p>
            </div>

            <div className="process">           
              <div className="process-left">
              <p className="half-textlarge">
              6 key research insights 
              </p>
              <p>
              We created clusters of similar problems & 
              opinions, which after cross checking with 
              interviews and quantitative data allowed 
              us to come up with actionable insights.  
              </p>                
              </div>
            </div>

            <div className="image">
              <Img fluid={props.data.tecneprocess21.childImageSharp.fluid} />
            </div>

            <p className="full-textlarge">
            For each insight we translated them into opportunities 
            by reframing them as “How Might We” questions. 
            These questions formed the basis for idea 
            generation workshops. 
            </p>

            <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.tecneprocess22.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess23.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess24.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess25.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess26.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess27.childImageSharp.fluid} />
            </Slider>

            <div className="changebg">
            <div className="process">           
            <div className="process-left">
              <h4>
              Idea generation & insight validation workshops
              </h4>
              </div>
              <div className="process-right">
              <p>
              After understanding what is the thing that we are 
              trying to solve, we organised workshops from October 
              till December 2019 where we aimed to seek multiple 
              directions where we could explore our solutions.  
              </p>               
              </div>
            </div>

            <div className="image">
              <Img fluid={props.data.tecneprocess28.childImageSharp.fluid} />
            </div>

            <div className="process">           
            <div className="process-left">
              <p className="half-textlarge">
              We carried our workshop in different cities from southern 
              to northern Europe, with participants from  various 
              backgrounds. This was done to ensure that we get a 
              diverse and inclusive point of view.
              </p>
              </div>
              <div className="process-right">
              <p>
              We relied on co-creation practices to guide our 
              design process as we believe that co-designing 
              a sustainable solution through discussion and 
              brainstorming was the best solution to achieve 
              our goal.  
              </p>
              <p>
              People from different parts of Europe have 
              a varied outlooks, mindset and behaviours 
              towards this topic.   
              </p>
              <p>
              As facilitators we planned different 
              formats of workshops and prepared 
              canvases and exercises for the participants.  
              </p>
              <p>
              We invited participants who had a variety of 
              backgrounds; from privacy & policy experts 
              and researchers to brand managers, product 
              owners and consumers; everyone shared their 
              thoughts and ideas for the smart home context.  
              </p> 
              <div className="image">
              <Img fluid={props.data.tecneprocess29.childImageSharp.fluid} />
            </div>              
              </div>
            </div>

            <div className="image">
              <Img fluid={props.data.tecneprocess30.childImageSharp.fluid} />
            </div>

            <div className="process">           
            <div className="process-right">
              <p>
              The co-creation workshops allowed us to:
              </p>
                <ol>
                  <li>
                  Raise awareness about how personal data is used and its value
                  </li>
                  <br />
                  <li>
                  Identify current user behaviours and their data sharing practices
                  </li>
                  <br />
                  <li>
                  Understand businesses' and experts' opinion 
                  on how they handle user’s data and its privacy 
                  & transparency
                  </li>
                  <br />
                  <li>
                  Validate and receive guidance around our insights & assumptions
                  </li>
                  <br />
                  <li>
                  Define a common vision/strategy around the IoT Privacy in the UX context
                  </li>
                  <br />
                  <li>
                  Get initial ideas to our problem 
                  </li>
                </ol>
              </div>
            </div>
            </div>

            <div className="process">           
            <div className="process-left">
              <h4>
              Concept definition & prototyping the b-smart ecosystem
              </h4>
              </div>
              <div className="process-right">
              <p className="half-textlarge">
              4 idea directions from our workshops  
              </p>
              <p>
              After our workshops we clustered our findings into various ideas:   
              </p>
              <ol>
                  <li>
                  Raising awareness and knowledge about the topic in a simple way that is easy to digest.
                  </li>
                  <br />
                  <li>
                  Managing & controlling data settings and exchanges in an intuitive manner.
                  </li>
                  <br />
                  <li>
                  Increasing data transparency to improve trust between brands and users.
                  </li>
                  <br />
                  <li>
                  Creating a personal data and value exchange channel that is fair, flexible and personalized.
                  </li>
                </ol>               
              </div>
            </div>

            <div className="image">
              <Img fluid={props.data.tecneprocess31.childImageSharp.fluid} />
            </div>

            <div className="process">           
            <div className="process-left">
            <p>
            After another round of internal ideation session, we decided 
            to focus on the top 3 idea directions and develop them 
            further into a holistic concept.  
            </p>
            </div>
            </div>

            <p className="full-textlarge">
            We went back to our user journey to understand how our 
            concept fits across the entire user's journey; from 
            awareness, setting up of device, usage and end of 
            device life. 
            </p>

            <div className="image">
              <Img fluid={props.data.tecneprocess32.childImageSharp.fluid} />
            </div>

            <div className="image">
              <Img fluid={props.data.tecneprocess33.childImageSharp.fluid} />
            </div>

            <div className="image">
              <Img fluid={props.data.tecneprocess34.childImageSharp.fluid} />
            </div>

            <div className="changebg">    
            <div className="process">           
            <div className="process-left">
              <h4>
              Business model explorations
              </h4>
              </div>
              <div className="process-right">
              <p>
              Finally we went to Valencia to explore how we 
              could sustain our design solution with a viable 
              business model.   
              </p>
              <p>
              By outlining the values of our solution/concept, 
              the target users, channels and touchpoints 
              in the ecosystem, we defined multiple business 
              models and its possible evolution roadmap.   
              </p>               
              </div>
            </div>

            <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.tecneprocess35.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess36.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess37.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess38.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess39.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess40.childImageSharp.fluid} />
            </Slider>
            </div>

          </section>




          <section id="outcome-section" ref={outcomeDiv} className="outcome-section">
            <div className="outcome-text-wrapper">
              <span className="project-step-number">03</span>
              <h2>the outcome</h2>

              <div className="outcome">
              <div className="outcome-right">
              <p>
              With the advent of a large number of connected objects 
              it is of fundamental importance to give to all users a 
              means to control their data, and help create an experience 
              that users can trust. B-smart does this!   
              </p>
              <p>
              Our solution is based on 4 key strategies values:   
              </p>
              <ul>
                <li>
                Transparency - let me see what is happening to my data
                </li>
                <li>
                Tangibility - making the invisible; visible
                </li>
                <li>
                Autonomy - let me control my data
                </li>
                <li>
                Intuitive - humanize IoT devices
                </li>
              </ul>

              <p>
              These strategies were implemented across the entire 
              journey of the user where we developed a clear & 
              positive user experience in setting the privacy 
              controls of connected objects, by realising an easy, 
              understandable yet complete UX.  
              </p>
              </div>
              </div>

              <h3>
              b-smart allows users to control their digital privacy, 
              security, and wellbeing in ways that feels right to them. 
              It helps them make more informed choices and change 
              their habits in ways that suit them; this in-turn 
              results in an experience that users can trust.
              </h3>
              
              <h3>
              Overall we found a fine balance between creating an
              IoT experience that was user-friendly (not confusing) but at 
              the same time transparent (not a black-box).
              </h3>
              
              <br />

              <div className="outcome">
              <div className="outcome-right">
              <p>
              In the next phase of the project, we had planned to
              create a MVP of the concept, which unfortunately got
              delayed due to the COVID-19 pandemic.
              </p>
              </div>
              </div>

              
            </div>
          </section>
        </div>
      </div>


      <section className="fullspan">
        <div className="image">
          <Img fluid={props.data.tecneoutcome41.childImageSharp.fluid} />
        </div>
      </section>

      
      <div className="project-wrapper">
        <div className="project-content">
          {/* <section className="process"> */}
          <div className="changebg">

          
          <div className="process">           
            <div className="process-left">
              <h4>
              Problems faced during the project 
              and how they were solved
              </h4>
              </div>
              <div className="process-right">
              <p>
              Getting people to talk about an important, 
              but highly complex topic was difficult.
              </p> 
              <p>
              We approached people in different ways (short survey, 
              contextual interview, phone conversation, in-person 
              chats) depending on their comfort. Our strategy involved 
              raising awareness around the topic, and being open to 
              listening to stories about people's personal experiences. 
              </p>               
              </div>
            </div>
            

            <div className="process">           
              <div className="process-right">
              <p>
              Facilitating workshops in different cities and
              with people (academics) who knew more about 
              this topic than us was a challenging task.
              </p> 
              <p>
              We understood our strength and weaknesses, and 
              depending on the participants, we planned
              our workshops accordingly in terms of what we aimed to gain from it.
              We were humble and willing to learn from these 
              experts during the workshop and in exchange we 
              equipped these academics with design thinking tools 
              to design user-centric solutions together. 
              </p>               
              </div>
            </div>
            <br />
            <br />
            <div className="process">           
            <div className="process-left">
                  <h4>
                  Reflection - what I learnt during the project & what I could have done better.
                  </h4>
            </div>
              <div className="process-right">
              <p>
              It was an eye-opening experience for me to see the different mindset and outlook
              that people from different regions in Europe have towards this topic.   
              </p> 
              <p>
              The intitial presentation of the concepts behind the complex topic like 
              privacy and data transparency could have been 
              presented in a more engaging and interesting 
              way rather than a simple slide presentation.
              </p>               
              </div>
            </div>
          </div>
          <br />
          
          
          <p className="full-text">
            Thank you & have a secure digital life! <span role="img" aria-labelledby="bsmartemoji">🔒🔑</span> 
          </p>
          <br />
          <br />
          <br />
          <br />
          <br />
          
          <div className="full-text">
          <div className="hp-featured__hr-line" />
          <h5>NEXT PROJECT</h5>
          </div>
          <br />


          {/* </section> */}
        </div>
      </div>

        
      {/* </div> */}


    


    


    {/* try from featured page section */}
    {/* <div id="main"> */}
      <section id="two" className="tiles inner">
      {/* <div className="hp-featured__hr-line" />
        <h4>NEXT PROJECT</h4> */}
            <div className="tiles__wrapper">
              <a href="/work/service-design-mercedes">
                <div className="tiles__content">
                  <div className="tiles__info">
                    {/* <div className="tiles__number">01</div> */}

                    {/* <h2 className="tiles__title">
                      electric car charging service.
                    </h2> */}

                    <div className="tiles__text">
                    A seamless parking & charging solution for Mercedes Benz &
                    Smart Electric Car owners living in urban areas.
                    </div>
                    <br />
                    <p className="maisontags-card">Research, Service Design, User Experience, Digital UX/UI, Interaction design</p>
                    {/* <div className="tiles__tags">
                      <ul>
                        <li>SERVICE DESIGN</li>
                        <li>USER EXPERIENCE</li>
                        <li>DIGITAL UX/UI</li>
                        <li>INTERACTION DESIGN</li>
                      </ul>
                    </div> */}
                  </div>
                </div>
                <div className="tiles__image">
                  <img src={pic02} alt="" />
                </div>
              </a>
            </div>
      </section>

      
    </div>



    {/* <div className="footer-top">
      <div className="inner">

        <div className="topbar" />
        <div className="hp-featured__hr-line" />
        <h4>NEXT PROJECT</h4>
        <div id="work">
          <div className="card-wrapper">
            <a href="/work/service-design-mercedes">
              <div className="image">
                <Img fluid={props.data.project1.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">electric car charging service.</div>
                <div className="text">
                  A seamless parking & charging solution for Mercedes Benz &
                  Smart Electric Car owners living in urban areas.
                </div>
                <div className="tags">
                  <ul>
                    <li>SERVICE DESIGN</li>
                    <li>USER EXPERIENCE</li>
                    <li>DIGITAL UX/UI</li>
                    <li>INTERACTION DESIGN</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
          <div className="card-wrapper">
            <a href="/work/service-design-mercedes">
              <div className="image">
                <Img fluid={props.data.project1.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">electric car charging service.</div>
                <div className="text">
                Bringing a complete charging experience to Mercedes Benz 
                & Smart Electric Car owners  living in urban areas.
                </div>
                <br />
                <p className="maisontags-card">Service Design, User Experience, Digital UX/UI, Interaction design</p>
                <div className="tags">
                  <ul>
                    <li>SERVICE DESIGN</li>
                    <li>USER EXPERIENCE</li>
                    <li>UX STRATEGY</li>
                    <li>BUSINESS DESIGN</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div> */}



  </Layout>
)
}

export default ServiceDesignTecne

export const fluidTecneImage = graphql`
  fragment fluidTecneImage on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    tecnecover: file(
      relativePath: { eq: "projects/service/bsmart/cover/bsmart-cover-image.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecne_logo: file(
      relativePath: { eq: "projects/service/bsmart/bsmart-logo.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecnechallenge: file(
      relativePath: { eq: "projects/service/bsmart/challenge/1.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess2: file(
      relativePath: { eq: "projects/service/tecne/process/2.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess3: file(
      relativePath: { eq: "projects/service/bsmart/process/3.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess4: file(
      relativePath: { eq: "projects/service/bsmart/process/4.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess5: file(
      relativePath: { eq: "projects/service/bsmart/process/5.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess6: file(
      relativePath: { eq: "projects/service/bsmart/process/6.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess7: file(
      relativePath: { eq: "projects/service/bsmart/process/7.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess8: file(
      relativePath: { eq: "projects/service/bsmart/process/8.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess9: file(
      relativePath: { eq: "projects/service/bsmart/process/9.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess10: file(
      relativePath: { eq: "projects/service/bsmart/process/10.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess11: file(
      relativePath: { eq: "projects/service/bsmart/process/11.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess12: file(
      relativePath: { eq: "projects/service/bsmart/process/12.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess13: file(
      relativePath: { eq: "projects/service/bsmart/process/13.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess14: file(
      relativePath: { eq: "projects/service/bsmart/process/14.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess15: file(
      relativePath: { eq: "projects/service/bsmart/process/15.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess16: file(
      relativePath: { eq: "projects/service/bsmart/process/16.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess17: file(
      relativePath: { eq: "projects/service/bsmart/process/17.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess18: file(
      relativePath: { eq: "projects/service/bsmart/process/18.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess19: file(
      relativePath: { eq: "projects/service/bsmart/process/19.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess20: file(
      relativePath: { eq: "projects/service/bsmart/process/20.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess21: file(
      relativePath: { eq: "projects/service/bsmart/process/21.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess22: file(
      relativePath: { eq: "projects/service/bsmart/process/22.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess23: file(
      relativePath: { eq: "projects/service/bsmart/process/23.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess24: file(
      relativePath: { eq: "projects/service/bsmart/process/24.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess25: file(
      relativePath: { eq: "projects/service/bsmart/process/25.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess26: file(
      relativePath: { eq: "projects/service/bsmart/process/26.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess27: file(
      relativePath: { eq: "projects/service/bsmart/process/27.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess28: file(
      relativePath: { eq: "projects/service/bsmart/process/28.JPG" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess29: file(
      relativePath: { eq: "projects/service/bsmart/process/29.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess30: file(
      relativePath: { eq: "projects/service/bsmart/process/30.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess31: file(
      relativePath: { eq: "projects/service/bsmart/process/31.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess32: file(
      relativePath: { eq: "projects/service/bsmart/process/32.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess33: file(
      relativePath: { eq: "projects/service/bsmart/process/33.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess34: file(
      relativePath: { eq: "projects/service/bsmart/process/34.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess35: file(
      relativePath: { eq: "projects/service/bsmart/process/35.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess36: file(
      relativePath: { eq: "projects/service/bsmart/process/36.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess37: file(
      relativePath: { eq: "projects/service/bsmart/process/37.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess38: file(
      relativePath: { eq: "projects/service/bsmart/process/38.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess39: file(
      relativePath: { eq: "projects/service/bsmart/process/39.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess40: file(
      relativePath: { eq: "projects/service/bsmart/process/40.png" }
    ) {
      ...fluidTecneImage
    }
    
    tecneoutcome41: file(
      relativePath: { eq: "projects/service/bsmart/outcome/41.jpg" }
    ) {
      ...fluidTecneImage
    }
    things_logo: file(
      relativePath: { eq: "articles/mobileiot/things-logo.png" }
    ) {
      ...fluidTecneImage
    }
    tecne_process: file(
      relativePath: { eq: "projects/process/bsmart-process.png" }
    ) {
      ...fluidTecneImage
    }
    project1: file(relativePath: { eq: "projects/3.png" }) {
      ...fluidTecneImage
    }
    project2: file(relativePath: { eq: "projects/10.png" }) {
      ...fluidTecneImage
    }
  }
`
